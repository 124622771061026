import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import SyllablesEvaluation from "../../../../../components/syllables-evaluation"
import FeedbackHeaderProgress from "../../../../../components/feedback-header-progress"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const { getAnswer, getTask, getStatsForTaskWithDict } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })
  const taskProps = {
    courseId: "haelfte-des-lebens",
    chapterId: "01-zwei-haelften",
    taskId: "silben-klopfen",
  }
  const task = getTask(taskProps)
  const answer = getAnswer(taskProps)
  const statistics = getStatsForTaskWithDict(taskProps)

  const RenderAnswer = ({ id, children }) => {
    return (
      <SyllablesEvaluation
        answer={answer?.[id]}
        solution={task.solution[id]}
        key={`answer_${id}`}
      >
        {children}
      </SyllablesEvaluation>
    )
  }

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/silben-klopfen/zusammenfassung" />
      }
    >
      <Seo title="Silben klopfen" />
      <Stack>
        <FeedbackHeaderProgress
          correct={statistics.correct}
          total={statistics.correct + statistics.incorrect + statistics.missed}
        />
        <Stack space="6">
          <RenderAnswer id="q-01">
            <strong>Hälf-te des Le-bens</strong>
          </RenderAnswer>
          <RenderAnswer id="q-02">Mit gel-ben Bir-nen häng-et</RenderAnswer>
          <RenderAnswer id="q-03">Und voll mit wil-den Ro-sen</RenderAnswer>
          <RenderAnswer id="q-04">Das Land in den See,</RenderAnswer>
          <RenderAnswer id="q-05">Ihr hol-den Schwä-ne,</RenderAnswer>
          <RenderAnswer id="q-06">Und trun-ken von Küs-sen</RenderAnswer>
          <RenderAnswer id="q-07">Tunkt ihr das Haupt</RenderAnswer>
          <RenderAnswer id="q-08">
            Ins hei-lig-nüch-ter-ne Was-ser.
          </RenderAnswer>
          <RenderAnswer id="q-09">Weh mir, wo nehm’ ich, wenn</RenderAnswer>
          <RenderAnswer id="q-10">
            Es Win-ter ist, die Blu-men, und wo
          </RenderAnswer>
          <RenderAnswer id="q-11">Den So-nnen-schein,</RenderAnswer>
          <RenderAnswer id="q-12">Und Scha-tten der Er-de?</RenderAnswer>
          <RenderAnswer id="q-13">Die Mau-ern stehn</RenderAnswer>
          <RenderAnswer id="q-14">Sprach-los und kalt, im Win-de</RenderAnswer>
          <RenderAnswer id="q-15">Klir-ren die Fah-nen.</RenderAnswer>
        </Stack>
        <Paragraph>
          Anders als in Hölderlins sehr regelmäßig gebauten Oden-Strophen lässt
          sich in diesem Gedicht auf den ersten Blick kein Muster erkennen. Doch
          sehen wir einmal weiter.
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
